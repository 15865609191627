<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">游客量监测区域</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="MC"
                    label="景点名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="关联遗产要素"
                    prop="YCYSBM"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.YCYSBM"
                        placeholder="请选择遗产要素"
                        :disabled="!isEdit"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in YcysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="WZSM"
                    label="位置描述"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.WZSM"
                        type="textarea"
                        resize="none"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="RYKLXZZ"
                    label="日游客量限制值"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.RYKLXZZ"
                        @input="toNumber($event, 'RYKLXZZ')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SSYKLXZZ"
                    label="瞬时游客量限制值"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SSYKLXZZ"
                        @input="toNumber($event, 'SSYKLXZZ')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <!--经纬度 选取-->
                <el-form-item
                    label="游客区域坐标位置"
                    prop="JWD"
                    class="from-item-block"
                >
                    <div class="flex">
                        <div style="flex: 1 1">
                            <span>经度：</span>
                            <el-input
                                v-model="formdata.dataDic.JD"
                                :disabled="!isEdit"
                                style="width: calc(100% - 50px)"
                                @input="toDecimal($event, 'JD', 3, 5)"
                            ></el-input>
                        </div>
                        <div style="flex: 1 1">
                            <span>纬度：</span>
                            <el-input
                                v-model="formdata.dataDic.WD"
                                :disabled="!isEdit"
                                style="width: calc(100% - 50px)"
                                @input="toDecimal($event, 'WD', 3, 5)"
                            ></el-input>
                        </div>
                        <div>
                            <el-button size="small" @click="showPickup = true"
                                >坐标拾取</el-button
                            >
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="是否有瞬时游客量" class="from-item-block">
                    <el-radio
                        v-model="formdata.dataDic.SFSSJR"
                        label="0"
                        :style="{ 'margin-top': '8px' }"
                        @change="(val) => changeProp(val, 'SFSSJR')"
                        :disabled="!isEdit"
                    >
                        否
                    </el-radio>
                    <el-radio
                        v-model="formdata.dataDic.SFSSJR"
                        label="1"
                        :style="{ 'margin-top': '8px' }"
                        @change="(val) => changeProp(val, 'SFSSJR')"
                        :disabled="!isEdit"
                    >
                        是
                    </el-radio>
                </el-form-item>
                <el-form-item
                    label="照片上传"
                    class="from-item-block select_pic_item"
                >
                    <div class="flex">
                        <div class="flex">
                            <div
                                v-for="(file, i) in fileList"
                                :key="file.Guid"
                                style="margin-right: 10px"
                            >
                                <div class="imgbox">
                                    <div class="delbtn">
                                        <el-button
                                            type="info"
                                            icon="el-icon-delete"
                                            circle
                                            @click="handleDeletePic(file, i)"
                                        ></el-button>
                                    </div>
                                    <el-image
                                        style="width: 148px; height: 148px"
                                        :src="file.url"
                                        fit="cover"
                                    ></el-image>
                                </div>
                                <div>
                                    <el-radio
                                        v-model="formdata.dataDic.SYTID"
                                        :label="file.Guid"
                                        :style="{ 'margin-top': '8px' }"
                                        @change="
                                            (val) => changeProp(val, 'SYTID')
                                        "
                                        :disabled="!isEdit"
                                    >
                                        设置为展示图片
                                    </el-radio>
                                </div>
                            </div>
                        </div>
                        <el-upload
                            v-if="isEdit"
                            ref="upload"
                            :action="`${BASE_URL}UpLoad/FileSave`"
                            list-type="picture-card"
                            accept="image/png,image/jpg,image/jpeg,image/gif"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :on-success="handleAvatarSuccess"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>

                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />`
                    </el-dialog>
                </el-form-item>
            </el-form>
        </div>
        <!--点选弹框-->
        <coordinatePickup
            :editState="isEdit"
            :showPickupP.sync="showPickup"
            :coordinate.sync="JWD"
            @clickPoint="clickPoint"
        ></coordinatePickup>
    </div>
</template>

<script>
import coordinatePickup from "@comp/map/coordinate_pickup";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "monitor",
    components: {
        coordinatePickup,
    },
    mixins: [infoMixin],
    data() {
        let _this = this;
        function jwdValidator(rule, val, callback) {
            const value =
                !!_this.formdata.dataDic.JD && !!_this.formdata.dataDic.WD;
            if (!value) {
                callback(new Error("请输入或点选坐标位置"));
            } else {
                callback();
            }
        }
        return {
            YcysList: [],
            showPickup: false,
            isEdit: false,
            saveButton: true,
            JWD: "",
            formdata: {
                itemCode: "1107",
                dataDic: {
                    glycbtid: this.heritageId,
                    YCYSBM: "",
                    MC: "",
                    WZSM: "",
                    RYKLXZZ: "",
                    SSYKLXZZ: "",
                    JD: "",
                    WD: "",
                    SFSSJR: "1",
                    SYTID: "",
                },
                metaJson: [],
            },
            rules: {
                MC: [
                    {
                        required: true,
                        message: "请填写景点名称",
                        trigger: "blur",
                    },
                ],
                JWD: [
                    {
                        required: true,
                        validator: jwdValidator,
                        trigger: ["blur", "change"],
                    },
                ],
            },
            enumData: [],
            BASE_URL: window.REQUEST_URL,
            dialogImageUrl: "",
            dialogVisible: false,
            delFileList: [],
            fileList: [],
            sytidList: [true],
        };
    },
    created() {},
    mounted() {
        this.getYcysDataList();
    },
    methods: {
        ...mapActions(["GetYcysList"]),
        // 获取遗产要素列表
        async getYcysDataList() {
            let result = await this.GetYcysList({});
            this.YcysList = result.ResultValue;
        },
        GetDataNext() {
            this.fileList =
                this.restData &&
                this.restData.PIC &&
                this.restData.PIC.map(
                    ({ fjid: Guid, fjmc: name, path: url }) => ({
                        Guid,
                        name,
                        url,
                    })
                );
        },
        clickPoint() {
            let value = this.JWD.split(",");
            this.formdata.dataDic.JD = value[0];
            this.formdata.dataDic.WD = value[1];
        },
        changeProp(value, prop) {
            this.formdata.dataDic[prop] = value;
        },
        // 操作图片的三个方法
        handleRemove(file, fileList) {
            this.delFileList.push(
                (file.response || { ResultValue: [{ Guid: null }] })
                    .ResultValue[0].Guid
            );
            // this.fileList = fileList;
            console.log("============", file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleAvatarSuccess(res, file) {
            console.log(res, file, this.fileList);
            const {
                ResultValue: [{ Guid, FilePath: url, FileName: name }],
            } = res;
            this.fileList.push({
                Guid,
                url,
                name,
            });
            if (!this.formdata.dataDic.SYTID) {
                this.formdata.dataDic.SYTID = Guid;
            }
            this.createMetaJson(res);
        },
        createMetaJson(response) {
            let newFile = {};
            let result = response.ResultValue[0];
            newFile.fjmc = result.FileName;
            newFile.path = result.FilePath;
            newFile.jcsjid = result.Guid;
            newFile["tpmc"] = result.FileName.substring(
                0,
                result.FileName.lastIndexOf(".")
            );
            newFile.code = "JCSJ";
            newFile.shzt = 1;
            this.formdata.metaJson.push(newFile);
        },
        handleDeletePic(file, i) {
            this.fileList = this.fileList.filter(
                (ele) => ele.Guid !== file.Guid
            );
            if (this.formdata.dataDic.SYTID === file.Guid) {
                this.formdata.dataDic.SYTID = "";
            }
            if (this.fileList.length > 0) {
                this.formdata.dataDic.SYTID = this.fileList[0].Guid;
            }
        },
    },
};
</script>

<style lang="scss">
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
.select_pic_item .el-form-item__content > div > div > ul {
    display: none;
}
.select_pic_item .el-form-item__content {
    div.imgbox {
        position: relative;
        &:hover div.delbtn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        div.delbtn {
            position: absolute;
            display: none;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
        }
    }
}
</style>
